import styled, { device } from "@styled";
export const SectionWrap = styled.section`
    padding:0px 0 40px 0;
    ${device.small} {
        padding:0px 0 60px 0;
    }
    ${device.xlarge} {
        padding:0px 0 80px 0;
    }
    .container{
        ${device.xlarge} {
            max-width:1570px;
        }
    }
`;
export const TranslateBottomBox = styled.div`
    text-align: center;
    padding:0px 15px;
    border-radius: 24px;
    // background-image: linear-gradient(234deg, #15054f 0%, #521680 100%);
    position:relative;
    z-index:1;
    ${device.small} {
        padding:0px 25px;
    }
    ${device.large} {
        padding:0px 40px;
    }
    h3{
        font-size: 26px;
        color: #050017;
        font-weight: bold;
        line-height:1.2;
        margin:0 auto 15px;
        max-width:1165px;
        ${device.small} {
            font-size: 32px;
            margin:0 auto 20px;
        }
        ${device.large} {
            font-size: 36px;
        }
        ${device.xxlarge} {
            font-size: 40px;
            margin:0 auto 30px;
        }
        ${device.xxlarge} {
            font-size: 48px;
            margin:0 auto 40px;
        }
    }  
`
export const TestimonialText = styled.p`
    font-size: 16px;
    line-height: 1.4;
    color: #050017;
    font-style: italic;
    margin:0 auto;
    max-width:1264px;
    ${device.small} {
        font-size: 18px;
    }
    ${device.xlarge} {
        font-size: 20px;
    }
`
export const TestimonialSubTitle = styled.h5`
    
`